import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'
// import { createLogger } from 'redux-logger'
import allReducers from './reducers'

// const logger = createLogger()
const middlewares = [thunk] // [thunk, logger]

export default function configureStore (initialState) {
  return createStore(
    allReducers,
    composeWithDevTools(
      applyMiddleware(...middlewares)
    ),
    initialState
  )
}
