/**
 * Field warnings.
 */
const warnings = {
  reallyEmpty: (value) => {
    if (!value || value.length < 1) {
      return 'Do you really want to leave this empty?'
    }
  },

  writeMore: (value) => {
    if (value && value.length < 10) {
      return 'I bet you have more to say'
    }
    return undefined
  },
}

export default warnings
