/**
 * Sticky Nav reducer.
 * @param state
 * @param action
 * @returns {*}
 */
export const contactFormResults = (state = false, action) => {
  switch (action.type) {
    case 'SUBMIT_CONTACT_LOADING':
      return { isLoading: action.isLoading }
    case 'SUBMIT_CONTACT_ERROR':
      return { error: action.error }
    case 'SUBMIT_CONTACT_SUCCEEDED':
      return action.values
    default:
      return state
  }
}
