/**
 * Set window sizes.
 * @param width
 * @returns {{width: *, type: string}}
 */
export function setWindowWidth (width) {
  return {
    type: 'SET_WINDOW_WIDTH',
    width
  }
}
