const testimonials = [
  {
    name: 'Francisco (Vicente López, Buenos Aires, Argentina)',
    pic: 'flower-1.jpg',
    text: 'Los encuentros con Silvia fueron un aprendizaje y descubrimiento de cuestiones internas dormidas. Pude desenredar nudos emocionales, y desechar inseguridades y miedos que surgieron a lo largo del tiempo, casi sin darme cuenta. En este proceso de despertar, aprendí a valorizar mis fortalezas, mis capacidades y mi experiencia vivida, para así, confiar en mí y amar lo que hago. Asistir a estas sesiones de coaching con Silvia y seguir esta evolución, fue una de las mejores decisiones que tomé en este año.'
  },
  {
    name: 'Cecilia (Barcelona, España)',
    pic: 'flower-2.jpg',
    text: 'Conocí a Silvia en un momento convulso de mi vida. Mi hija había fallecido y a los pocos meses operaron a mi madre de un cáncer. Era la primera vez que sentía que necesitaba ayuda, que mis herramientas no eran suficientes. La dificultad de hacerlo por Skype (yo vivo en España y Silvia en Argentina) se fueron diluyendo en sesiones que me ayudaron a asumir y a ir superando ese momento, con armas lógicas que todos tenemos pero que quedan bloqueadas ante determinadas situaciones. Silvia también me acompañó en mi posterior embarazo, que no fue fácil. Hoy miro a esa etapa con nostalgia pero afortunadamente el dolor profundo de aquellos días ha desaparecido. Y Silvia es parte de todo esto. Gracias.'
  },
  {
    name: 'Laura (Berazategui, Buenos Aires, Argentina)',
    pic: 'flower-3.jpg',
    text: 'Cuando comencé mis encuentros con Silvia, no tenía experiencia en los recursos del coaching ontológico. Planteamos un problema, una decisión y trabajamos en ella. Desde ese momento a hoy me ha acompañado en el proceso de maternidad, en cuestiones de pareja, de familia y laborales. De esto hace cinco años ya y sigo contando con su labor y he recomendado a amigas que también han podido comprobar el compromiso, profesionalidad y capacidad de Silvia. Sin duda, se plantea como una posibilidad de contención y acompañamiento idóneo para los tiempos que corren y los problemas que enfrentamos en lo cotidiano. Con un profundo agradecimiento y la confianza de que es una gran coach la recomiendo  ya que hay mucho de ella que excede la formación y hace de ella una gran aliada.'
  },
  {
    name: 'Lucas (Capital Federal, Argentina)',
    pic: 'flower-4.jpg',
    text: 'En lo personal y profesional, Silvia me desafió a observarme y así darme cuenta de mis capacidades y potencias. Gracias a ello, pude destrabar emociones y sentimientos acumulados a lo largo de 20 años, permitiéndome tener una imagen clara de quien soy y, de esta manera, empoderarme para construir el futuro que yo quiero.'
  },
  {
    name: 'Pilar (Castilla, España)',
    pic: 'flower-5.jpg',
    text: 'Me resistía iniciar un proceso de coaching para poder lograr un objetivo que es muy de raíz y tan personal como el encuentro de mi propia identidad pero afirmo que 3 años más tarde celebro feliz el haber superado esa resistencia. Que Silvia sea quien me haya acompañado en este viaje fue fundamental. Su empatía y dedicación, y por supuesto, su experiencia y su talento para diseñar nuevas formas de abordaje a mis cuestiones me hizo creer que TODO era posible, y LO ES reencontrándome con todas mis herramientas y desarrollando otras. Logré con creces muchas metas y sigo mi camino de aprendizaje continuo, pero siempre le estaré agradecida de repetirme como un mantra: ‘SI LO CREES, LO CREAS’. Infinitas gracias de corazón.'
  },
  {
    name: 'Analía (Capital Federal, Argentina)',
    pic: 'flower-6.jpg',
    text: 'El aporte que me generó a nivel laboral, las sesiones de coaching con Silvia fueron: entender que puedo actuar con margen de acción y que no siempre tengo que hacer las cosas para que el otro se sienta bien, es decir, actuar bajo el criterio que defina sin que eso dependa de otra subjetividad o humores ajenos y sin condicionamientos. En definitiva, aceptar que a todos no voy a agradar, y que tampoco voy a dejar a todos conformes. Poder decir No, fijando un límite evaluando prioridad y urgencia de atención de lo que se me requiera. Confiar en mis herramientas y poder de utilizarlas, confiando plenamente en mí. Visualizar que debo gestionar mis emociones, y entender que es un proceso lograrlo.'
  },
  {
    name: 'Carla (Avellaneda, Buenos Aires, Argentina)',
    pic: 'flower-7.jpg',
    text: 'Silvia representa para mí haber encontrado por primera vez, después de años y años de terapias, de búsquedas alo y homeopáticas, chinas, acupuntura, etc. LA asistencia y EL acompañamiento que nunca tuve. Muy concreta, muy empática, se OCUPA, tiene memoria y, si es necesario, te para el carro, algo excelente para mí. Es la primera vez en mil años de probar y probar tratamientos, que mi colon irritable está empezando a dejarme tranquila. Sobre todo, en comprender que los problemas, enfermedades y malos entendidos no son sólo individuales sino también sociales. Trabajamos a veces directo con el cuerpo, algo ideal para una persona tan mental como yo. Y ahora sí me ánimo a muchas cosas más y estoy cada día más empoderada. Gracias a vos, Silvia: te quiero mucho y más.'
  },
  {
    name: 'Julián (Berazategui, Buenos Aires, Argentina)',
    pic: 'flowers-various.jpg',
    text: 'Los encuentros con Silvia fueron un aprendizaje y descubrimiento de cuestiones internas dormidas. Pude desenredar nudos emocionales, y desechar inseguridades y miedos que surgieron a lo largo del tiempo, casi sin darme cuenta. En este proceso de despertar, aprendí a valorizar mis fortalezas, mis capacidades y mi experiencia vivida, para así, confiar en mí y amar lo que hago. Asistir a estas sesiones de coaching con Silvia y seguir esta evolución, fue una de las mejores decisiones que tomé en este año.'
  }
]

export default testimonials
