import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Modal, Button } from 'react-bootstrap'
import { setCvTab } from '../../store/actions/setCvTab'

class ModalCv extends Component {
  constructor (props) {
    super(props)
    this.onClickSetCvTab = this.onClickSetCvTab.bind(this)
  }

  onClickSetCvTab = (e, tab) => {
    this.props.setCvTab(tab)
  }

  render () {
    return (
      <Modal
        show={this.props.show}
        aria-labelledby='contained-modal-title-vcenter'
        centered
      >
        <Modal.Body>
          <h1>Curriculum Vitae (CV)</h1>
          <div className="cv-container">
            <div className="cv-tabs">
              <div className={`cv-tabs intro${this.props.cvTab === 'intro' ? ' active' : ''}`}
                   onClick={(e) => this.onClickSetCvTab(e, 'intro')}>Introducción</div>
              <div className={`cv-tabs coaching${this.props.cvTab === 'coaching' ? ' active' : ''}`}
                   onClick={(e) => this.onClickSetCvTab(e, 'coaching')}>Coaching Ontológico</div>
              <div className={`cv-tabs historia${this.props.cvTab === 'historia' ? ' active' : ''}`}
                   onClick={(e) => this.onClickSetCvTab(e, 'historia')}>Lic. en Historia</div>
              <div className={`cv-tabs seguros${this.props.cvTab === 'seguros' ? ' active' : ''}`}
                   onClick={(e) => this.onClickSetCvTab(e, 'seguros')}>Seguros</div>
            </div>
            {this.props.cvTab === 'intro' &&
            <div className={`cv-content intro${this.props.cvTab === 'intro' ? ' active' : ''}`}>
              <div className="cv-content-wrapper">
                <h2>Introducción</h2>
                <p>Compromiso, sensibilidad por el otro y su contexto, un espíritu forjado en el crecimiento personal y profesional conforman un perfil con valores íntegros que rigen en su hacer y que conforman la mirada del entorno de igual manera. Silvia es una exploradora nata: en su vida y profesionalmente lo que la llevó a comprender el significado de la autonomía y autorrealización. Su búsqueda, devenida con rigurosidad, en investigadora científica de los aspectos sociales y políticos de Latinoamérica. Búsqueda que se concretó por un lado en su carrera de grado como historiadora y en la indagación del ser que se cristalizó como Coach ontológico.</p>
                <p>Multifacética con cualidades en la que se destaca la comunicación con un compromiso profundo por los derechos humanos y de la mujer, lo social y lo particular son intereses predominantes en su vida. Docente, investigadora y empresaria en el sector de seguros delinean un espíritu energético, altruista y generoso, que la configuran en una aliada perfecta y respetuosa.</p>
                <p>Mujer de ideas, mujer de valores, mujer del hacer.</p>
              </div>
            </div>
            }
            {this.props.cvTab === 'coaching' &&
            <div className={`cv-content coaching${this.props.cvTab === 'coaching' ? ' active' : ''}`}>
              <div className="cv-content-wrapper">
                <h2>Nivel terciario</h2>
                <h3><i>Coaching</i> ontológico Personal:</h3>
                <p><u>2012</u>: Egresada del ITC7 Instituto de Transformación y <i>Coaching</i>. Egresada en 2012 del Instituto de Transformación y <i>Coaching</i> como <i>Coach</i> Ontológica Personal, con aval de la Asociación Argentina de Profesionales del <i>Coaching</i>.</p>
                <h3>Estudios complementarios al <i>Coaching</i></h3>
                <ul>
                  <li><u>Febrero de 2017</u>: <strong>Didacta <i>"Taquion"</i>, facilitadora del Juego</strong>: <strong>"TAO"</strong> para el desarrollo de recursos de comunicacionales y de liderazgo personal.</li>
                  <li><u>Agosto 2018</u>: <strong>Curso de <i>Storytelling</i></strong> para incorporar herramientas narrativas que permiten crear vínculos de calidad en el ámbito personal y laboral. <i>Empowering Influence</i>.</li>
                  <li><u>Diciembre de 2019</u>: <strong>Didacta de la formación "Nosotras las Diosas"</strong>, para obtener herramientas que permitan diagnosticar y acrecentar cualidades, energías y sabidurías provenientes de los arquetipos femeninos.</li>
                  <li><u>Junio de 2020</u>: <strong>Didacta de la formación "Fénix"</strong>, para transitar duelos de manera generativa, dejando atrás el pasado y  para avanzar a una  nueva etapa de vida, conectando con la energía personal de superación.</li>
                </ul>
              </div>
            </div>
            }
            {this.props.cvTab === 'historia' &&
            <div className={`cv-content historia${this.props.cvTab === 'historia' ? ' active' : ''}`}>
              <div className="cv-content-wrapper">
                <h2>Licenciada en historia</h2>
                <ul>
                  <li>Historiadora especializada en la estructura económica de América Latina y su formación dependiente.</li>
                  <li>Integrante del Grupo de Trabajo CLACSO "Geopolítica, Sistema Mundial e Integración Latinoamericana".</li>
                  <li>En desarrollo: Especialización en la China Contemporánea (Universidad de Lanús).</li>
                  <li>Profesora en Enseñanza Media y Superior.</li>
                  <li>Licenciada en Historia (Universidad de Buenos Aires).</li>
                  <li>2014: Colaboradora y organizadora de actividades de Prensa para UNASUR en Argentina.</li>
                </ul>
              </div>
            </div>
            }
            {this.props.cvTab === 'seguros' &&
            <div className={`cv-content seguros${this.props.cvTab === 'seguros' ? ' active' : ''}`}>
              <div className="cv-content-wrapper">
                <h2>Seguros</h2>
                <p><u>Agosto de 2012</u>: Otorgamiento de la marca AS de calidad, certificada por la Asociación Argentina de Productores Asesores de Seguros, que indica que la socia Silvia Laura Rodríguez cumple con los requisitos de aptitud, trayectoria, solvencia y antecedentes necesarios para el eficiente desempeño de su actividad como asesor profesional independiente.</p>
              </div>
            </div>
            }
          </div>
        </Modal.Body>

        {/* Buttons */}
        <Modal.Footer>
          <Button variant='default' onClick={this.props.onHide}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}

ModalCv.propTypes = {
  setCvTab: PropTypes.func,
  cvTab: PropTypes.string,
}

const mapStateToProps = (state) => {
  return {
    cvTab: state.cvTab
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setCvTab: (tab) => dispatch(setCvTab(tab))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ModalCv))
