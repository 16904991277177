import config from '../../config/config'
import { SubmissionError } from 'redux-form'

/**
 * Saving contact message.
 * @param bool
 * @returns {{type: string, error: *}}
 */
export const submitContactLoading = (bool) => {
  return {
    type: 'SUBMIT_CONTACT_LOADING',
    isLoading: bool
  }
}

/**
 * Saving contact message, failed.
 * @param error
 * @returns {{type: string, error: *}}
 */
export const submitContactError = (error) => {
  return {
    type: 'SUBMIT_CONTACT_ERROR',
    error
  }
}

/**
 * Saving contact message.
 * @param values
 * @returns {{type: string, codes: *}}
 */
export const submitContactSucceeded = (values) => {
  return {
    type: 'SUBMIT_CONTACT_SUCCEEDED',
    values
  }
}

/**
 * Save contact form data.
 * @param formData
 * @returns {Promise<never|Promise<any>|any>}
 */
const saveContactFormData = async (formData) => {
  let response = {}
  const url = `${config.apiUrl}/contacts`

  try {
    response = await window.fetch(
      url,
      {
        method: 'POST',
        body: JSON.stringify(formData),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
  } catch (error) {
    throw new Error(`Bad response from server: ${error}`)
  }

  if (response.status >= 400) {
    const error = await response.json()
    let errorMsg = `Bad response from server (${response.status})`
    if (error && error.badRequest && error.badRequest.sqlMessage) {
      errorMsg = error.badRequest.sqlMessage
    } else if (error && error.badRequest && error.badRequest.details) {
      errorMsg = []
      error.badRequest.details.map(e => errorMsg.push(e.message))
      errorMsg.join('\r\n')
    }
    throw new Error(errorMsg)
  } else {
    return response.json()
  }
}

/**
 * Submit a redux-form.
 * @param values
 * @param dispatch
 * @returns {Promise<*>}
 */
async function submitContactForm (values, dispatch) {
  try {
    if (!values) {
      return Error('Missing data')
    }
    dispatch(submitContactLoading(true))
    const response = await saveContactFormData(values)
    if (((!response || !response.id) || response.id < 1) && response.length < 1) {
      dispatch(submitContactLoading(false))
      return dispatch(submitContactError('Server error (1)'))
    } else {
      dispatch(submitContactLoading(false))
      return dispatch(submitContactSucceeded(Object.assign({id: response.id}, values)))
    }
  } catch (error) {
    dispatch(submitContactLoading(false))
    return dispatch(submitContactError(new SubmissionError(`${error} - ${values}`)))
  }
}

export default submitContactForm
