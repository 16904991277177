/**
 * Modal visibility. Reducer.
 * @param state
 * @param action
 * @returns {*}
 */
export const modalVisibility = (state = false, action) => {
  switch (action.type) {
    case 'SET_MODAL_VISIBILITY':
      return action.visibility
    default:
      return state
  }
}
