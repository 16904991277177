import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from './store/store'
import routing from './routes/routes.js'
import 'bootstrap/dist/css/bootstrap.min.css'
import './styles/stylesheet.scss'

const configureStore = store()
ReactDOM.render(
  <Provider store={configureStore}>
    {routing}
  </Provider>,
  document.getElementById('root')
)

if (module.hot) {
  module.hot.accept()
}
