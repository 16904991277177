/**
 * CV tab. Reducer.
 * @param state
 * @param action
 * @returns {*}
 */
export const cvTab = (state = 'intro', action) => {
  switch (action.type) {
    case 'SET_CV_TAB':
      return action.tab
    default:
      return state
  }
}
