import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Container, Row, Col } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import '../../styles/stylesheet.scss'
import { withRouter } from 'react-router'
import NavigationBar from '../../components/NavigationBar/NavigationBar'
import Testimonials from '../../components/Testimonials/Testimonials'
import Contact from '../../components/Contact/Contact'
import ModalCv from '../../components/ModalCv/ModalCv'
import { setStickyNav } from '../../store/actions/setStickyNav'
import { setModalVisibility } from '../../store/actions/setModalVisibility'

class Main extends Component {
  constructor (props) {
    super(props)
    this.handleScroll = this.handleScroll.bind(this)
    this.onClickMenuHandler = this.onClickMenuHandler.bind(this)
    this.onClickHandleModal = this.onClickHandleModal.bind(this)
  }

  menuStatusHandler = (item, e) => {
    const menuItems = document.querySelectorAll('.menu-item')
    const menuItemRef = e.target.getAttribute('id')

    if (menuItems && menuItemRef) {
      for (let i = 0; i < menuItems.length; i++) {
        if (menuItems[i].id === menuItemRef) {
          menuItems[i].classList.add('active')
        } else {
          menuItems[i].classList.remove('active')
        }
      }
    }
  }

  onClickMenuHandler = (item, e) => {
    e.preventDefault()
    const menuItemRef = e.target.getAttribute('id')
    const contentRef = document.getElementById(item).getBoundingClientRect()

    this.menuStatusHandler(item, e)

    if (contentRef && menuItemRef) {
      const menuHeight = document.getElementById('mainMenu').offsetHeight
      const extraTopPadding = 30
      const goTo = item === 'inicio' ? 0 : contentRef.top + window.scrollY - menuHeight - extraTopPadding
      window.scrollTo({
        top: goTo,
        behavior: 'smooth'
      })
    }
  }

  handleScroll = (e) => {
    this.props.setStickyNav(window.pageYOffset > 50)
  }

  onClickHandleModal = (item, e) => {
    e.preventDefault()
    e.stopPropagation()
    this.menuStatusHandler(item, e)
    this.props.setModalVisibility(!this.props.modalVisibility)
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
  }

  render() {
    const currentDate = new Date()
    const copyrightYears = currentDate.getFullYear()

    return (
      <React.Fragment>
        {this.props.modalVisibility &&
        <ModalCv
          show={this.props.modalVisibility}
          onHide={(e) => this.onClickHandleModal('cv', e)}
        />
        }
        <div id='mainMenu'
             className={`navigation-wrapper${this.props.stickyNav ? ' sticky' : ''}`}
        >
          <Container className={'navigation-container'}>
            <NavigationBar onClickHandler={this.onClickMenuHandler} />
          </Container>
        </div>

        <div className='hero'
             id='inicio'
             onClick={(e) => this.onClickMenuHandler('tematicas', e)}>
          <Container>
            <h1 id='inicio'
                onClick={(e) => this.onClickMenuHandler('tematicas', e)}>traves&iacute;a</h1>
            <h2 id='inicio'
                onClick={(e) => this.onClickMenuHandler('tematicas', e)}>Silvia Rodríguez <span>~</span> <i>Coaching</i> ontológico</h2>
          </Container>
        </div>

        <Container>
          <Row>
            <Col>
              <div className="section-content" id="tematicas">
                <h2>Temáticas</h2>
                <p className="lead">Un viaje a través de vos, con la asistencia del <i>coaching</i>, por el logro de tus objetivos.</p>
                <p>¡Bienvenido/a a mi website!</p>
                <p>Aquí les comparto las nociones fundamentales de mi trabajo como <i>coach</i> y los testimonios de algunos de mis <i>coachees</i>, que dan cuenta de mi función.<br />
                En la disciplina del <i>coaching</i> ontológico, el <i>coachee</i> o interesado/a plantea al inicio los objetivos que desea lograr. A partir de mi formación y experiencia, asisto a la persona en el proceso necesario para concretar sus metas.</p>
                <p>En este proceso se abordan muchas temáticas. Algunas de ellas son las siguientes:</p>
                <div className='responsive-table'>
                  <div className='table-col-1'>
                    <div>- Desarrollo personal</div>
                    <div>- Identidad</div>
                    <div>- Familia</div>
                    <div>- Maternidad</div>
                  </div>
                  <div className='table-col-2'>
                    <div>- Salud</div>
                    <div>- Relaciones de pareja</div>
                    <div>- Relaciones laborales</div>
                  </div>
                </div>
              </div>
              <div className="section-content" id="silvia">
                <h2>Silvia</h2>
                <p className="lead">"Una aliada respetuosa y comprometida."</p>
                <p><img src="assets/silvia.jpg" className="pic-silvia mr-4" alt="Silvia" align="left" /><i>"Conocí a Silvia en el 2013. Desde ese momento me acompañó en diferentes decisiones y en un trayecto muy importante en mi vida: decisiones de pareja, decisiones que tuvieron que ver con un proceso de fertilidad que no prosperó ya que se dio en el marco de un corte de relación. Gracias a su experticia y compromiso, en esa primera etapa comprendí que el camino que se abría bajo la búsqueda de la maternidad tenía que ver con un trabajo interno que Silvia enfocó en el merecimiento. La ruptura de la relación de pareja, si bien fue sentida, fue una consecuencia natural de verme y poner mi autoestima en primer lugar y, por supuesto, en darme el cuidado y amor que en lo cotidiano ponía bajo la ponderación del que había sido mi pareja. Esta comprensión fue bajo su tutela y guía."</i></p>
                <p><i>"A partir de ese momento, el trabajo con Silvia se adaptó a otros requerimientos y se pudo abrir años después una segunda etapa que se corona con el nacimiento de mi hija en un parto diseñado entre dos: activamente con mi pareja generamos estrategias en base a todos nuestros recursos emocionales que permitieron que el parto fuera con amor para todas las partes. En esa instancia destaco un compartir minuto a minuto con Silvia en confianza mutua."</i></p>
                <p><i>"Este nacimiento nos puso en la experiencia de convivir con la discapacidad y, una vez más, con Silvia nos renovamos en otro nuevo proceso: el ma-paternar viendo el potencial bellísimo de nuestra hija."</i></p>
                <p><i>"En estas líneas resumo seis años de mi vida con muchos cambios y desafíos en los que he confiado estos momentos tan caros y tan sentidos por mí en los que pude disfrutar incluso lo que otros considerarían adversidad desde la entrañable compañía de Silvia. Gracias a su impecabilidad ética y su valor de compromiso he recomendado a mi pareja y a amigas muy cercanas y queridas. Si hay una síntesis es que con su labor nos permite el registro y reconocernos en nuestras capacidades, diseñar estrategias propias, ya que el camino es con -y no por- el <i>coachee</i>. Los procesos son más livianos, claros y cuidadosos con nosotros mismos."</i></p>
                <p>Testimonio de <strong>Maru (Buenos Aires, Argentina)</strong></p>
              </div>

              <div className="section-content" id="testimonios">
                <h2>(Más) Testimonios</h2>
                <Testimonials />
              </div>
            </Col>
          </Row>
        </Container>
        <div className="contact" id="contactar">
          <Container>
            <h1 className="pb-2">Contactar</h1>
            <p>Para contactarte podés escribirme a <strong>silvia [at] travesia-coaching.com</strong> o podés también usar el formulario debajo.<br />
            Estoy también disponsible en varias plataformas sociales (ver pie de pantalla).</p>
            <Contact />
          </Container>
        </div>
        <div className="footer">
          <Container>
            <div className="footer-container">
              <div>Derechos reservados &copy; {copyrightYears} Silvia Rodríguez</div>
              <div className="social-media-links">
                <a href="https://www.instagram.com/travesia_coaching/" target="_blank" rel="noopener noreferrer">
                  <img src="assets/sm-icons/instagram.svg" alt="Instagram" />
                </a>
                <a href="https://www.facebook.com/profile.php?id=100033224741633" target="_blank" rel="noopener noreferrer">
                  <img src="assets/sm-icons/facebook.svg" alt="Facebook" />
                </a>
                <a href="https://wa.me/447960093094" target="_blank" rel="noopener noreferrer">
                  <img src="assets/sm-icons/whatsapp.svg" alt="WhatsApp" />
                </a>
                <a href="https://www.linkedin.com/in/travesia-silvia-rodriguez-coaching-ontologico-4310a31b4/" target="_blank" rel="noopener noreferrer">
                  <img src="assets/sm-icons/linkedin.svg" alt="LinkedIn" />
                </a>
              </div>
            </div>
          </Container>
        </div>
      </React.Fragment>
    )
  }
}

Main.propTypes = {
  setStickyNav: PropTypes.func,
  stickyNavStatus: PropTypes.bool,
  modalVisibility: PropTypes.bool
}

const mapStateToProps = (state) => {
  return {
    stickyNav: state.stickyNav,
    modalVisibility: state.modalVisibility
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setStickyNav: (pageY) => dispatch(setStickyNav(pageY)),
    setModalVisibility: (visibility) => dispatch(setModalVisibility(visibility))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main))
