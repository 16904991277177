/**
 * Set mobile menu visibility.
 * @param bool
 * @returns {{type: string, loading: *}}
 */
export function setMobileViewerVisibility (bool) {
  return {
    type: 'SET_MOBILE_VIEWER_VISIBILITY',
    visibility: bool
  }
}
