const windowWidthSize = document.body.clientWidth

/**
 * Sticky Nav reducer.
 * @param state
 * @param action
 * @returns {*}
 */
export const windowWidth = (state = windowWidthSize, action) => {
  switch (action.type) {
    case 'SET_WINDOW_WIDTH':
      return action.width
    default:
      return state
  }
}
