import React from "react"

export const renderTextField = ({input, label, placeholder, type, legend, required, meta: {touched, error, warning}}) => (
  <div className={`form-group${touched && error ? ' error' : ''}${touched && warning ? ' info' : ''}`}>
    <label>{label} {required ? <span className='text-danger'>*</span> : ''}</label>
    {legend && legend.length > 0 &&
    <div className={'small text-secondary mt-5 pb-10'}>{legend}</div>
    }
    <input {...input}
           className={'form-control width-with'}
           placeholder={placeholder}
           type={type}
           // autofill="off"
           // autoComplete="off"
    />
    {touched && ((error && <div className={'text-error'}>{error}</div>) || (touched && warning &&
      <div className={'text-info'}>{warning}</div>))}
  </div>
)

export const renderTextarea = ({input, label, placeholder, legend, required, meta: {touched, error, warning}}) => (
  <div className={'form-group' + ((touched && error) ? ' error' : '') + ((touched && warning) ? ' info' : '')}>
    <label>{label} {required ? <span className={'text-danger'}>*</span> : ''}</label>
    {legend && legend.length > 0 &&
    <div className={'small text-secondary mt--5 pb-10'}>{legend}</div>
    }
    <textarea
      {...input}
      className={'form-control'}
      placeholder={placeholder}
    />
    {touched && ((error && <div className={'text-error'}>{error}</div>) || (touched && warning &&
      <div className={'text-info'}>{warning}</div>))}
  </div>
)

/**
 * Render a hidden field.
 */
export const renderHiddenField = ({input}) => {
  return (
    <div>
      <input {...input} type={'hidden'} />
    </div>
  )
}
