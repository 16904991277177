import config from './../../../config/config'
import getGlobals from './../../../config/globalStrings'

/**
 * Async redux-form validation.
 * @param values
 */
const validation = values => {
  const errors = {}
  const globalStrings = getGlobals(config.defaultLanguage)
  if (!values.firstName) {
    errors.firstName = 'Required'
  } else if (values.firstName.length < 3) {
    errors.firstName = globalStrings.FORM_CONTACT_INVALID_FIRST_NAME
  }
  if (!values.email) {
    errors.email = 'Required'
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = globalStrings.FORM_CONTACT_INVALID_EMAIL
  }
  return errors
}

export default validation
