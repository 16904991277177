import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { setMobileViewerVisibility } from '../../store/actions/setMobileViewerVisibility'
import { setModalVisibility } from '../../store/actions/setModalVisibility'
import { setWindowWidth } from '../../store/actions/setWindowWidth'

class NavigationBar extends Component {
  constructor (props) {
    super (props)
    this.onClickMobileMenuVisibility = this.onClickMobileMenuVisibility.bind(this)
    this.onClickGoTo = this.onClickGoTo.bind(this)
    this.onClickHandleModal = this.onClickHandleModal.bind(this)
    this.updateWindowWidth = this.updateWindowWidth.bind(this)
  }

  onClickMobileMenuVisibility = (e) => {
    this.props.setMobileViewerVisibility(!this.props.mobileViewerVisibility)
  }

  onClickGoTo = (whereToGo, e) => {
    this.props.onClickHandler(whereToGo, e)
    this.props.setMobileViewerVisibility(false)
  }

  onClickHandleModal = (item, e) => {
    this.props.setModalVisibility(!this.props.modalVisibility)
    this.props.setMobileViewerVisibility(false)
  }

  updateWindowWidth = () => {
    this.props.setWindowWidth(window.innerWidth)
  }

  componentDidMount () {
    window.addEventListener('resize', this.updateWindowWidth)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowWidth);
  }

  render () {
    const menuItemsVisibility = this.props.windowWidth || document.body.clientWidth

    return (
      <nav>
        <div id="mobile-viewer"
             className="mobile-viewer"
             onClick={(e) => this.onClickMobileMenuVisibility(e)}
        >
          <span />
          <span />
          <span />
        </div>
        <div className="menu-container">
          {(menuItemsVisibility >= 992 ? true : this.props.mobileViewerVisibility) &&
          <ul id="menu-items">
            <li>
              <button
                type="button"
                className="menu-item btn btn-link"
                id="menu-inicio"
                onClick={(e) => this.onClickGoTo('inicio', e)}>Inicio
              </button>
            </li>
            <li>
              <button
                type="button"
                className="menu-item btn btn-link"
                id="menu-tematicas"
                onClick={(e) => this.onClickGoTo('tematicas', e)}>Temáticas
              </button>
            </li>
            <li>
              <button
                type="button"
                className="menu-item btn btn-link"
                id="menu-silvia"
                onClick={(e) => this.onClickGoTo('silvia', e)}>Silvia
              </button>
            </li>
            <li>
              <button
                type="button"
                className="menu-item btn btn-link"
                id="menu-testimonios"
                onClick={(e) => this.onClickGoTo('testimonios', e)}>Testimonios
              </button>
            </li>
            <li>
              <button
                type="button"
                className="menu-item btn btn-link"
                id="menu-contactar"
                onClick={(e) => this.onClickGoTo('contactar', e)}>Contactar
              </button>
            </li>
            <li className="cv-menu-item">
              <button
                type="button"
                className="menu-item btn btn-link"
                id="menu-cv"
                onClick={(e) => this.onClickHandleModal('cv', e)}>CV
              </button>
            </li>
          </ul>
          }
        </div>
      </nav>
    )
  }
}

NavigationBar.propTypes = {
  setMobileViewerVisibility: PropTypes.func,
  setModalVisibility: PropTypes.func,
  mobileViewerVisibility: PropTypes.bool
}

const mapStateToProps = (state) => {
  return {
    mobileViewerVisibility: state.mobileViewerVisibility,
    modalVisibility: state.modalVisibility,
    windowWidth: state.windowWidth
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setMobileViewerVisibility: (visibility) => dispatch(setMobileViewerVisibility(visibility)),
    setModalVisibility: (visibility) => dispatch(setModalVisibility(visibility)),
    setWindowWidth: (windowWidth) => dispatch(setWindowWidth(windowWidth))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NavigationBar))
