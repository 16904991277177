import { reducer as reduxFormReducer } from 'redux-form'
import { combineReducers } from 'redux'
import { contactFormResults } from './contactFormResults'
import { cvTab } from './cvTab'
import { mobileViewerVisibility } from './mobileViewerVisibility'
import { modalVisibility } from './modalVisibility'
import { stickyNav } from './stickyNav'
import { testimonialNumber } from './testimonialNumber'
import { windowWidth } from './windowWidth'

export default combineReducers({
  form: reduxFormReducer,
  contactFormResults,
  cvTab,
  mobileViewerVisibility,
  modalVisibility,
  stickyNav,
  testimonialNumber,
  windowWidth
})
