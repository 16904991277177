import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { reduxForm, getFormSyncErrors } from 'redux-form'
import { Field } from 'redux-form'
import { renderTextField, renderTextarea } from './lib/fieldRenderers'
import submitContactForm from '../../store/actions/submitContactForm'
import warnings from './lib/warnings'
import validation from './lib/validation'
import config from './../../config/config'
import getGlobals from './../../config/globalStrings'

class Contact extends Component {
  constructor (props) {
    super(props)
    this.globalStrings = getGlobals(config.defaultLanguage)
    this.formSettings = [
      {
        key: 'contact-name',
        name: 'firstName',
        type: 'text',
        label: 'Nombre',
        placeholder: 'Nombre',
        component: renderTextField,
        required: true
      },
      {
        key: 'contact-email',
        name: 'email',
        type: 'email',
        label: 'Email',
        placeholder: 'Email',
        component: renderTextField,
        required: true
      },
      {
        key: 'contact-message',
        name: 'message',
        type: 'text',
        label: 'Mensaje',
        placeholder: 'Escribir mensaje',
        component: renderTextarea,
        required: true
      }
    ]
  }

  buttonStatus = () => {
    return(
      !this.props.contactForm ||
      (this.props.contactForm && !this.props.contactForm.values) ||
      (this.props.contactForm && this.props.contactForm.values && (
        !this.props.contactForm.values.firstName ||
        !this.props.contactForm.values.email ||
        !this.props.contactForm.values.message)
      ) ||
      (this.props.formSyncErrors && Object.keys(this.props.formSyncErrors).length > 0) ||
      !!this.props.submitting
    )
  }

  render () {
    const {
      pristine,
      submitting,
      reset,
      handleSubmit
    } = this.props

    if (this.props.contactFormResults && this.props.contactFormResults.firstName && this.props.contactFormResults.email) {
      return (
        <div className="message-received">{this.globalStrings.FORM_CONTACT_MESSAGE_RECEIVED}</div>
      )
    }

    return (
      <div>
        {(this.props.contactFormResults && this.props.contactFormResults.isLoading) &&
          <div className='contact-msg saving'>Enviando mensaje...</div>
        }
        {(this.props.contactFormResults && this.props.contactFormResults.error) &&
        <React.Fragment>
          <div className='contact-msg error'>{`Ha ocurrido un error: ${this.props.contactFormResults.error}`}</div>
        </React.Fragment>
        }
        <form onSubmit={handleSubmit(submitContactForm)} encType="multipart/form-data">
          {
            this.formSettings.map(item => {
              return (
                <Field
                  key={item.key}
                  name={item.name}
                  type={item.type}
                  label={item.label}
                  placeholder={item.placeholder}
                  component={item.component}
                  required={item.required}
                />
              )
            })
          }
          <div className="btn-group">
            <button className={`btn btn-primary ${this.buttonStatus() ? 'disabled' : ''}`}
                    type="submit"
                    disabled={this.buttonStatus()}>{submitting ? 'Saving' : 'Guardar'}
            </button>

            <button className="btn btn-secondary"
                    type="button"
                    disabled={pristine || submitting}
                    onClick={reset}>Borrar
            </button>
          </div>
        </form>
      </div>
    )
  }
}

Contact.propTypes = {
  contactForm: PropTypes.object,
  contactFormResults: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ])
}

Contact = reduxForm({
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  form: 'contactForm',
  validate: validation,
  warnings
})(Contact)

const mapStateToProps = (state) => {
  return {
    contactForm: state.form.contactForm,
    contactFormResults: state.contactFormResults,
    formSyncErrors: getFormSyncErrors('contactForm')(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {}
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Contact))
