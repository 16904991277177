/**
 * Set modal visibility.
 * @param bool
 * @returns {{type: string, loading: *}}
 */
export function setModalVisibility (bool) {
  return {
    type: 'SET_MODAL_VISIBILITY',
    visibility: bool
  }
}
