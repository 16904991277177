/**
 * Sticky Nav reducer.
 * @param state
 * @param action
 * @returns {*}
 */
export const stickyNav = (state = false, action) => {
  switch (action.type) {
    case 'SET_STICKY_NAV_LOADING':
      return { loading: action.loading }
    case 'SET_STICKY_NAV':
      return action.status
    default:
      return state
  }
}
