const globalString = {
  en : {
    FORM_CONTACT_INVALID_FIRST_NAME: 'Invalid first name, please enter at least 3 letters',
    FORM_CONTACT_INVALID_EMAIL: 'Invalid email address',
    FORM_CONTACT_INVALID_MESSAGE: 'Invalid message, please enter at least 3 words',
    FORM_CONTACT_MESSAGE_RECEIVED: 'You\re message has been received. Thank you.'
  },
  es : {
    FORM_CONTACT_INVALID_FIRST_NAME: 'Nombre inválido, escribe por lo menos 3 letras',
    FORM_CONTACT_INVALID_EMAIL: 'Correo electrónico inválido',
    FORM_CONTACT_INVALID_MESSAGE: 'Mensaje inválido, escribe al menos 3 palabras',
    FORM_CONTACT_MESSAGE_RECEIVED: 'Tu mensaje ha sido recibido. Gracias.'
  }
}

const getGlobals = (lang) => {
  return globalString[lang]
}

export default getGlobals
