/**
 * Set stickyNav: loading
 * @param bool
 * @returns {{type: string, loading: *}}
 */
export function setStickyNavLoading (bool) {
  return {
    type: 'SET_STICKY_NAV_LOADING',
    loading: bool
  }
}

/**
 * Set stickyNav
 * @param bool
 * @returns {{type: string, status: *}}
 */
export function setStickyNav (bool) {
  return {
    type: 'SET_STICKY_NAV',
    status: bool
  }
}
