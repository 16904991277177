/**
 * Set CV TAB.
 * @param tab
 * @returns {{type: string, loading: *}}
 */
export function setCvTab (tab) {
  return {
    type: 'SET_CV_TAB',
    tab
  }
}
