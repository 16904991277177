import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from "react-router"
import { connect } from "react-redux"
import { setTestimonialNumber } from '../../store/actions/setTestimonialNumber'
import testimonials from '../../data/testimonials'

class Testimonials extends Component {
  constructor(props) {
    super(props)
    this._onClickSlider = this._onClickSlider.bind(this)
  }

  _onClickSlider = (num) => {
    this.props.setTestimonialNumber(num)
  }

  loopThroughTestimonials = () => {
    const items = []
    const itemCount = []
    for (let i = 0; i < testimonials.length; i++) {
      items.push(
        <blockquote key={`testimonial-wrapper-${i}`}
             className={`testimonials-item ${i === this.props.testimonialNumber ? 'active' : ''}`}>
          <div key={`testimonial-text-${i}`}
               className='testimonial-text'
               dangerouslySetInnerHTML={{__html: testimonials[i].text}}
          />
          <div key={`testimonial-profile-${i}`}
               className='testimonials-profile'>
            <img key={`testimonial-avatar-${i}`}
                 className='testimonials-avatar'
                 src={`assets/${testimonials[i].pic}`}
                 alt={testimonials[i].pic}
            />
            <div key={`testimonial-name-${i}`}
                 className='testimonials-name'
                 dangerouslySetInnerHTML={{__html: `${testimonials[i].name}`}}
            />
          </div>
        </blockquote>
      )
      itemCount.push(
        <label
          key={`testimonial-slider-${i}`}
          className={`testimonials-count ${i === this.props.testimonialNumber ? 'active' : ''}`}
          onClick={(e) => this._onClickSlider(i)}
        />
      )
    }
    return {
      items,
      itemCount
    }
  }

  // sleep = (milliseconds) => {
  //   const date = Date.now()
  //   let currentDate = null
  //   do {
  //     currentDate = Date.now()
  //   } while (currentDate - date < milliseconds)
  // }

  // componentDidMount() {
  //   for (let i = 0; i < testimonials.length; i++) {
  //     this.sleep (2000)
  //     this._onClickSlider(i)
  //     console.log('Index', i)
  //   }
  // }

  render () {
    if (!testimonials) { return null }
    const testimonialItems = this.loopThroughTestimonials()
    return (
      <div className='testimonials-wrapper'>
        {testimonialItems.items}
        <div className='testimonials-control'>
          {testimonialItems.itemCount}
        </div>
      </div>
    )
  }
}

Testimonials.propTypes = {
  setTestimonialNumber: PropTypes.func,
  testimonialNumber: PropTypes.number
}

const mapStateToProps = (state) => {
  return {
    testimonialNumber: state.testimonialNumber
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setTestimonialNumber: num => dispatch(setTestimonialNumber(num))
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Testimonials))
