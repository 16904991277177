/**
 * Testimonials reducer.
 * @param state
 * @param action
 * @returns {*}
 */
export const testimonialNumber = (state = 0, action) => {
  switch (action.type) {
    case 'SET_TESTIMONIAL_NUMBER':
      return action.num
    default:
      return state
  }
}
