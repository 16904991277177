import { BrowserRouter as Router, Route } from 'react-router-dom'
import Main from '../components/Main/Main'
import React from 'react'

const routing = (
  <Router>
    <Route exact path='/' component={Main} />
    <Route exact path='/inicio' component={Main} />
    <Route exact path='/silvia' component={Main} />
    <Route exact path='/servicios' component={Main} />
    <Route exact path='/testimonios' component={Main} />
    <Route exact path='/contacto' component={Main} />
  </Router>
)

export default routing
